import clsx from 'clsx';
import { useState, useEffect, useCallback } from 'react';

import ParagraphHeader from 'components/ParagraphHeader';
import Markdown from 'components/common/Markdown';
import Link from 'components/common/Link';
import Button from 'components/common/Button';

import useMediaQuery from 'hooks/useMediaQuery';
import { LogoProps, SingleLogoProps } from 'interfaces/cms/content';
import SingleLogo from './SingleLogo';
import styles from './Logo.module.scss';

function Logo({
  logos,
  header,
  seeMoreButton,
  background,
  moreThanThisShowButton,
  itemsPerRow,
  remark,
  ctaButton,
}: LogoProps) {
  const [isMobile] = useMediaQuery(767);

  const [isShowAll, setIsShowAll] = useState(false);
  const [displayedLogos, setDisplayedLogos] = useState([]);

  const ctaButtonUrl =
    (ctaButton?.buttonPageSlug?.slug && `/${ctaButton.buttonPageSlug.slug}`) ||
    ctaButton?.buttonUrl;

  useEffect(() => {
    if (isShowAll) {
      setDisplayedLogos(logos);
      return;
    }
    setDisplayedLogos(
      logos.filter((_logoLimit, idx) => idx < moreThanThisShowButton)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logos, isShowAll]);

  const onShowAll = useCallback(() => {
    setIsShowAll((prev) => !prev);
  }, [isShowAll]);

  // align center when itemsPerRow is 6 but total logos are less than that
  const renderTemplateColumn = () => {
    if (itemsPerRow === 6 && itemsPerRow > logos.length && !isMobile) {
      return `repeat(${logos.length * 2}, 1fr)`;
    }
    return 'repeat(12, 1fr)';
  };

  return (
    <div
      className={clsx(styles.logo, 'px-[0.9375rem] py-[1.875rem]')}
      style={background && { backgroundColor: background }}
    >
      {header && (
        <div className="mb-[1.875rem]">
          <ParagraphHeader {...header} />
        </div>
      )}

      <div
        className={clsx(
          'max-w-[69.375rem] mx-auto items-center',
          'grid gap-[0.9375rem] lg:gap-[1.875rem]',
          displayedLogos.length < itemsPerRow
            ? 'justify-center'
            : 'justify-start'
        )}
        style={{ gridTemplateColumns: renderTemplateColumn() }}
      >
        {displayedLogos &&
          displayedLogos.map((singleLogo: SingleLogoProps) => {
            return (
              <SingleLogo
                key={singleLogo.id}
                id={singleLogo.id}
                logo={singleLogo.logo}
                url={singleLogo.url}
                linkRel={singleLogo.linkRel}
                pageSlug={singleLogo.pageSlug}
                content={singleLogo.content ? singleLogo.content : null}
                contentColor={
                  singleLogo.content ? singleLogo.contentColor : null
                }
                itemsPerRow={itemsPerRow}
                totalLogos={logos.length}
              />
            );
          })}
      </div>

      {remark && (
        <div
          className={clsx(
            styles.logo__remark,
            'text-[1.875rem] max-w-[69.375rem] mt-[1.875rem] mx-auto'
          )}
        >
          <Markdown textAlign="left" md={remark} textColor="#4b4b4b" />
        </div>
      )}

      {ctaButtonUrl && (
        <Link
          href={ctaButtonUrl}
          rel={ctaButton.buttonLinkRel}
          isExternalLink={!!ctaButton.buttonUrl}
          className="text-center mt-[1.875rem]"
        >
          <Button {...ctaButton} />
        </Link>
      )}

      {logos.length > moreThanThisShowButton && seeMoreButton && (
        <div className="text-center mx-auto w-full md:w-[31.25rem] mt-[1.875rem]">
          <Button
            isRowButton
            {...seeMoreButton}
            onClick={onShowAll}
            buttonLabel={
              isShowAll
                ? seeMoreButton.showLessLabel
                : seeMoreButton.seeMoreLabel
            }
            buttonType="outline"
            buttonSize="normal"
          />
        </div>
      )}
    </div>
  );
}

export default Logo;
