import Image from 'components/common/Image';
import { Image as ImageInterface } from 'interfaces/cms/common';
import useMediaQuery from 'hooks/useMediaQuery';
import styles from './ResponsiveImage.module.scss';
import Head from 'next/head';

export enum ArtDirection {
  RESPONSIVE = 'responsive',
  CROP = 'crop_center',
  USE_MOBILE = 'use_mobile_image',
}

export interface ResponsiveImageProps {
  src: ImageInterface;
  srcMobile?: ImageInterface;
  artDirection: string;
  width?: string | number;
  height?: string | number;
  priority?: boolean;
}

function ResponsiveImage({
  src,
  srcMobile,
  artDirection = 'responsive',
  width = 0,
  height = 0,
  priority = true,
}: ResponsiveImageProps) {
  const [isMobile] = useMediaQuery();

  const getArtDirection = (art_direction): ArtDirection => {
    switch (art_direction) {
      case 'crop_center':
        return ArtDirection.CROP;
      case 'use_mobile_image':
        return ArtDirection.USE_MOBILE;
      default:
        return ArtDirection.RESPONSIVE;
    }
  };

  const getAlt = (source: ImageInterface): string =>
    source?.alt || source?.name || 'image';

  if (getArtDirection(artDirection) === ArtDirection.CROP) {
    return (
      <>
        <Head>
          {priority && (
            <>
              {srcMobile && (
                <link
                  rel="preload"
                  href={srcMobile.url}
                  as="image"
                  media="(max-width: 768px)"
                />
              )}
              {src && (
                <link
                  rel="preload"
                  href={src.url}
                  as="image"
                  media="(min-width: 769px)"
                />
              )}
            </>
          )}
        </Head>
        <div className={styles.desktopImage}>
          <Image
            src={src.url}
            alt={getAlt(src)}
            sizes="100vw"
            style={{ width: '100%', height: 'auto' }}
            width={0}
            height={0}
            priority={priority}
          />
        </div>
        <div
          className={`${styles.mobileImage} ${styles.crop_center} `}
          style={{
            width,
            height,
            position: 'relative',
          }}
        >
          <Image src={src.url} alt={getAlt(src)} fill priority={priority} />
        </div>
      </>
    );
  }

  if (getArtDirection(artDirection) === ArtDirection.USE_MOBILE) {
    return (
      <>
        {isMobile ? (
          <Image
            src={srcMobile ? srcMobile.url : src.url}
            alt={srcMobile ? getAlt(srcMobile) : getAlt(src)}
            sizes="100vw"
            style={{
              width: '100%',
              height: 'auto',
            }}
            width={0}
            height={0}
            priority={priority}
          />
        ) : (
          <Image
            src={src.url}
            alt={getAlt(src)}
            sizes="100vw"
            style={{ width: '100%', height: 'auto' }}
            width={0}
            height={0}
            priority={priority}
          />
        )}
      </>
    );
  }
  return (
    <Image
      src={src?.url}
      alt={getAlt(src)}
      sizes="100vw"
      style={{ width: '100%', height: 'auto' }}
      width={0}
      height={0}
      priority={priority}
    />
  );
}

export default ResponsiveImage;
